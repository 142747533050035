import Button from "components/Button";
import CrossroadCard from "components/CrossroadCard";
import VenueCard from "components/VenueCard";
import { nav } from "config";
import { venues } from "config";

import cfLogo from "../cf2023_kormidlo_lod.svg";

import cfPattern from "./gingerbread-pattern-colourful-384px.svg";

const Home = () => {
  const delta = Math.abs(new Date(2023, 3, 29, 9, 0, 0) - new Date()) / 1_000;
  const days = Math.floor(delta / 86_400);
  const hours = Math.floor((delta - days * 86_400) / 3_600) % 24;

  return (
    <article className="space-y-8 md:space-y-16">
      <div className="bg-blue-300 text-white p-8 md:p-16">
        <div className="content-block">
          <h3 className="head-alt-md">Zasedání Celostátního fóra proběhlo 29.&nbsp;dubna 2023 v&nbsp;Domě Kultury v&nbsp;Liberci</h3>
          <h2 className="head-alt-md lg:head-alt-lg">
            Děkujeme všem za účast a těšíme se na vás na volebním zasedání Celostátního fóra v&nbsp;lednu 2024. 
          </h2>
          {/*
          <p className="text-md lg:text-lg">
            Zasedání bude možno sledovat on-line na adrese{" "}
            <a href="https://cf2023.online" className="font-bold">
              cf2023.online
            </a>
            .
          </p>
          */}
        </div>
  {/*
        <Button
          className="md:text-xl btn--to-blue-300"
          bodyClassName="whitespace-no-wrap"
          color="white"
          href="https://cf2023.online"
          icon="ico--chevron-right"
          rel="noreferrer noopener"
          target="_blank"
        >
          Sledovat CF 2023 online
        </Button>
  */}
      </div>

      <div className="content-block">
          <p className="text-md lg:text-lg">
            <strong>Letos se zasedání CF s doprovodným programem bude konat v&nbsp;Liberci v termínu 28.&nbsp;–&nbsp;30. dubna 2023.</strong><br/><br/>
            Po dlouhé době bude možnost se konečně osobně setkat, projednat, co je potřeba, vyměnit si zkušenosti napříč republikou a&nbsp;skvěle se pobavit. Setkání Celostátního fóra je stěžejní událost, kterou si umíme užít se vším všudy. I&nbsp;tentokrát nás čeká pestrý a&nbsp; pěkně nabitý program.<br/>
          </p>
      </div>

      <div className="grid md:grid-cols-3 gap-4 md:gap-8 content-block">
        {venues.map((venueItem) => (
          <VenueCard
            showtop="true"
            key={venueItem.title + "__" + venueItem.link}
            title_short={venueItem.title_short}
            startinfo={venueItem.startinfo}
            endinfo={venueItem.endinfo}
            street={venueItem.street}
            city={venueItem.city}
            gps={venueItem.gps}
            href={venueItem.href}
            title={venueItem.title}
            text={venueItem.text}
            infolink={venueItem.infolink}
            programlink={venueItem.programlink}
            accent={venueItem.accent}
            link={venueItem.link}
            underConstruction={!venueItem.link}
          />
        ))}
      </div>

      {/*    
      <div className="content-block alert alert--yellow-400 flex-col p-8 md:p-16">
          <h3 className="head-alt-md">Online registrace účastníků zasedání CF byla spuštěna</h3>
          <p className="text-md lg:text-lg">
            V rámci registrace budete požádáni o&nbsp;dobrovolný finanční příspěvek na pokrytí nákladů spojených se zajištěním akce, pronájmem prostoru a&nbsp;navázaných služeb. Veškeré důležité informace o&nbsp;konání budou průběžně aktualizovány na tomto webu.
          </p>
          <Button
              className="md:text-xl btn--to-blue-300"
              bodyClassName="whitespace-no-wrap"
              color="black"
              icon="ico--chevron-right"
              routerTo="/registrace"
            >
              Registrační formulář 
            </Button>
          <p className="text-md lg:text-lg pt-8">
            <strong>Rezervace ubytování není součástí registrace účastníků a každý účastník si ubytování řeší individuálně.<br/>
            Kapacity ubytování jsme měli v hotelích blokované pro rezervaci pouze do 12. dubna.
            Pokud nemáte rezervované ubytování, v tuto chvíli se můžete pouze inspirovat naší stránkou <a className="font-bold" href="ubytovani">rezervace ubytování</a>.<br/>
            </strong>  
          </p>

      </div>
      */}

      {/*
      <div className="relative card elevation-10 flex flex-col lg:flex-row lg:items-center my-8 xl:-mx-16 container-padding--zero md:container-padding--auto overflow-hidden">
        <div
          className="cf-hero"
          style={{ background: `url(${cfPattern})` }}
        ></div>
        <div className="cf-hero-claim w-full flex items-center justify-center text-center lg:text-left p-8 py-8 md:p-8">
          <img
            src={cfLogo}
            alt="CF 2023"
            className="hidden lg:block w-48 mr-16"
          />
          <div>
            <h1 className="head-alt-lg md:head-alt-xl mb-4">
              Začínáme za
              <br />
              {days} dní a {hours} hodin
            </h1>
            
            <Button
              className="md:text-xl btn--to-blue-300"
              bodyClassName="whitespace-no-wrap"
              color="black"
              routerTo="/program"
              icon="ico--chevron-right"
            >
              Program
            </Button>
            
          </div>
        </div>
      </div>
      */} 

      <div className="grid md:grid-cols-3 gap-4 md:gap-8">
        {nav.map((navItem) => (
          <CrossroadCard
            key={navItem.title + "__" + navItem.link}
            title={navItem.title}
            accent={navItem.accent}
            link={navItem.link}
            underConstruction={!navItem.link}
            notonhome={navItem.notonhome}
          />
        ))}
      </div>

      <div className="relative card elevation-10 flex flex-col lg:flex-row lg:items-center my-8 xl:-mx-16 container-padding--zero md:container-padding--auto overflow-hidden">
        <div
          className="cf-hero"
          style={{ background: `url(${cfPattern})` }}
        ></div>
        <div className="cf-hero-claim w-full flex items-center justify-center text-center lg:text-left p-8 py-8 md:p-8">
          <div>
            <h2 className="head-alt-lg md:head-alt-xl mb-4">
              Podpořte organizaci zasedání Celostátního fóra
            </h2>
            <div className="content-block">
              <p className="text-md lg:text-lg"> 
              Za organizací zasedání CF vždycky stojí práce řady dobrovolníků, kteří musejí věnovat mnoho hodin přípravě a zajištění klíčové věci - financování.<br/>
              Pojďme jim s tím pomoci. Podpořme největší pirátskou slezinu a vznik nových legendárních zážitků. Podpora této události rozhodně má smysl.<br/>Děkujeme.<br/><br/>              
              </p>
            </div>
            <Button
              className="md:text-xl btn--to-blue-300"
              bodyClassName="whitespace-no-wrap"
              color="black"
              icon="ico--chevron-right"
              href="https://dary.pirati.cz/projekty-kampane/cf-2023/"
              rel="noreferrer noopener"
              target="_blank"              
            >
              Podpořit darem 
            </Button>
          </div>
        </div>
      </div> 

    </article>
  );
};

export default Home;
