import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

import Button from "components/Button";
import VenueCard from "components/VenueCard";
import Badge from "components/Badge";
import { venues, organisers } from "config";


const Workshops = () => {
  const venueItem=venues[2];
  const person=organisers[0].members[2];  
  return (
    <article className="w-full">
      <PageTitle>Workshopy</PageTitle>

      <Helmet>
        <title>Workshopy | {basics.appTitle}</title>
        <meta property="og:title" content="Workshopy" />
      </Helmet>

      <div className="content-block">
        <h2>Nedělní workshopy v Kině Varšava</h2>
        <p>
          V jedinečných Art deco kulisách libereckého Kina Varšava se odehraje v neděli 30. dubna doprovodný program pro druhý den největší pirátské živé akce od roku 2019! Přijďte do centra Liberce, vzdělávejte se, networkujte a užijte si už neformální část programu bez usnesení a vážných obličejů.<br/><br/>
          <strong>Paralelně budou probíhat vždy 2 workshopy</strong> (kinosál a zasedací místnost v 1. patře). Po celou dobu programu bude účastníkům k dispozici prostor foyer kina s kavárnou a příjemným posezením.<br/><br/>          
          V pauze na oběd lze využít restauraci s pizzerií Maškovka, restauraci Radniční sklípek nebo fastfood v nákupním centru Plaza. Pro činovníky a pozvané hosty bude zajištěno mimo obědové občerstvení.<br/><br/>
        </p>
        <h3>Kafe se Zuzanou Freitas a Olgou Richterovou</h3>
        <p>
        Zajímá vás, co v sociální oblasti řešíme? V poslední době se toho povedlo hodně. Zákon o podpoře v bydlení v minulém týdnu zamířil do vnitřního připomínkování (na MMR a MPSV). Schvalovacím kolečkem prošel projekt na pilotování sousedských dětských skupin. Vypořádávají se připomínky k zákonu o sociálním podniku. Je svolaná pracovní skupina k revizi dávek.<br/><br/>
        Chcete se dozvědět víc? Chcete se zapojit do našeho rezortního týmu, ale nejdřív si nás chcete oťuknout? Doražte za námi na kávu! Posezení se bude konat v neděli v kavárně kina od 10:45 do 12:00.<br/><br/>
        </p>
      </div>

      <Button
          className="text-lg btn--to-blue-300"
          bodyClassName="whitespace-no-wrap"
          color="black"
          href="program#neděle 30. 4. 2023"
          icon="ico--chevron-right"
          >
          Harmonogram workshopů
        </Button>

      <div className="content-block p-2">  
      </div>  

      <div className="grid md:grid-cols-2 gap-16 content-block">
        <VenueCard
          street={venueItem.street}
          city={venueItem.city}
          gps={venueItem.gps}
          href={venueItem.href}
          title_short={venueItem.title_short}
        />
        <Badge
          name={person.name}
          position={person.position}
          avatarUrl={person.avatarUrl}
          phone={person.phone}
          email={person.email}
          key={person.name}
          />
      </div>

    </article>
  );
};

export default Workshops;
