import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

import Button from "components/Button";
import VenueCard from "components/VenueCard";
import { venues } from "config";


const Party = () => {
  const venueItem=venues[0];  
  return (
    <article className="w-full">
      <PageTitle>Preparty a Afterparty</PageTitle>

      <Helmet>
        <title>Preparty a Afterparty | {basics.appTitle}</title>
        <meta property="og:title" content="Preparty a Afterparty" />
      </Helmet>

      <div className="content-block">
        <h2>Warm-up Párty ve Spotu</h2>
        <p>
          O páteční zahřívací párty před sobotním zasedáním se postará <strong>Pirátská DJ crew v klubu Spot</strong>.<br/>
          <strong><a
              href="https://www.facebook.com/events/931574544927872/"
              target="_blank"
              rel="noreferrer noopener"
          >
          Pirate Electronic Party (Událost na FB)
          </a></strong>.<br/>
          Postupně zahrají Beedix, Electro Druid, Zdenek Key, Cejn, MARRA a Punkie alias Pan pouštěč.<br/>
          Těšit se můžete na breakový a housový rozjezd, dávku jemných psy dance rytmů, hybridní tech-trance, poctivý psytrance, surové techno a na závěr i DnB smršť. Start v pátek ve 21.00, konec s vítáním Slunce v sobotu 6.00 ráno.<br/><br/>
          Půjde o veřejně přístupnou párty, na kterou mají potvrzení účastníci CF2023 vstup zdarma.<br/><br/>
        </p>
        <table>
          <thead>
            <td colspan="2">
            Program / Line-up
            </td>
          </thead>
          <tbody>
          <tr>
            <td>21:00 - 22:30</td>
            <td>Beedix (breaks + house)</td>
          </tr>
          <tr>
            <td>22:30 - 00:00</td>
            <td>Electro Druid (psy dance)</td>
          </tr>
          <tr>
            <td>00:00 - 01:30</td>
            <td>Zdenek Key (techtrance)</td>
          </tr>
          <tr>
            <td>01:30 - 03:00</td>
            <td>Cejn (psy/trance/techno)</td>
          </tr>
          <tr>
            <td>03:00 - 04:30</td>
            <td>MARRA (techno)</td>
          </tr>
          <tr>
            <td>04:30 - 06:00</td>
            <td>Punkie (dnb)</td>
          </tr>
          </tbody>
        </table>
      </div>
      
      {/*
        <Button
          className="text-lg btn--to-blue-300 mt-8"
          bodyClassName="whitespace-no-wrap"
          color="black"
          href="program#pátek 28. 4. 2023"
          icon="ico--chevron-right"
          >
          Program Warm-up párty
        </Button>
      */}

      <div className="content-block p-2">  
      </div>  

      <div className="grid md:grid-cols-2 gap-4 md:gap-4 content-block">
        <VenueCard
          street={venueItem.street}
          city={venueItem.city}
          gps={venueItem.gps}
          href={venueItem.href}
          title_short={venueItem.title_short}
        />
      </div>

      <div className="content-block mt-8">
        <h2>Afterparty v Domě Kultury Liberec</h2>
        <p>
          Po skončení oficiální části zasedání bude v hlavním sále Domu Kultury odstartována Afterparty.<br/>
          Program se stále ladí. Konec okolo 2.00 v neděli ráno.
        </p>
        <table>
          <thead>
            <td colspan="2">
            Program / Line-up
            </td>
          </thead>
          <tbody>
          <tr>
            <td>19:00 - 20:30</td>
            <td>Johnny Adela (nu-disco)</td>
          </tr>
          <tr>
            <td>20:30 - 22:00</td>
            <td>Sanny (funky, deep house)</td>
          </tr>
          <tr>
            <td>22:00 - 23:30</td>
            <td>Omnion, one-man-self-looping-orchestra (world-trance)</td>
          </tr>
          <tr>
            <td>23:30 - 00:30</td>
            <td>Ivan Bartoš (psytrance)</td>
          </tr>
          <tr>
            <td>00:30 - 02:00</td>
            <td>Cejn (psytrance)</td>
          </tr>
          </tbody>
        </table>

      </div>
    </article>
  );
};

export default Party;
