export const basics = {
  appTitle: process.env.REACT_APP_CF_TITLE || "CF 2023",
  appDesc:
    process.env.REACT_APP_CF_DESC ||
    "Oficiální stránka letošního ročníku zasedání Celostátního fóra České pirátské strany, 29. 4. 2023.",
};

export const api = {
  baseUrl: process.env.REACT_APP_API_BASE_URL || "https://cf2023.pirati.cz/api",
};

export const appurl = {
  baseUrl: process.env.REACT_APP_ROOT_URL || "https://cf2023.pirati.cz",
};


export const eventHall = {
  text: "Zasedání CF 2023",
  title_short: "Dům Kultury Liberec",
  startinfo: "Sobota 29. dubna 09.00",
  endinfo: "Neděle 30. dubna 02.00",
  title: "Dům Kultury Liberec (místními přezdívaný Rakev)",
  desc: "Zasedání celostátního fóra v sobotu 29. 4. 2023 od 9.00",
  coords: { lat: 50.7656583, lon: 15.0533914 },
  street: "Soukenné náměstí 613",
  city: "146 00 Liberec",
  gps: "50.7656769N, 15.0533539E",
  href: "https://www.dkliberec.cz/",
  programlink: "program#sobota 29. 4. 2023",
  infolink: "zasedani",
  type: "event",
  group: "basics",
};

export const workshopVenue = {
  text: "Workshopy",
  title_short: "Kino Varšava",
  startinfo: "Neděle 30. dubna 09.30",
  endinfo: "Neděle 30. dubna 17.00",
  title: "Kino Varšava",
  desc: "Workshopy v neděli 30. 4. 2023 od 9.30",
  coords: { lat: 50.7712006, lon: 15.0566750 },
  street: "Frýdlantská 285/16",
  city: "460 01 Liberec I-Staré Město",
  gps: "50.7710422N, 15.0566653E",
  href: "https://kinovarsava.cz/",
  programlink: "program#neděle 30. 4. 2023",
  infolink: "workshopy",
  type: "event",
  group: "basics",
};

export const partyVenue = {
  text: "Warm-Up Párty",
  startinfo: "Pátek 28. dubna 21.00",
  endinfo: "Sobota 29. dubna 06.00",
  title_short: "Klub Spot",
  title: "Klub Spot",
  desc: "Warm-Up párty v pátek 29. 4. 2023 od 21.00",
  coords: { lat: 50.7639786, lon: 15.0391075 },
  street: "Švermova 62/19",
  city: "460 10 Liberec X-Františkov",
  gps: "50.7637511N, 15.0391397E",
  href: "https://www.facebook.com/SpotLbc",
  programlink: "program#pátek 28. 4. 2023",
  infolink: "party",
  type: "event",
  group: "basics",
};

export const railwayStation = {
  title: "Vlakové nádraží",
  desc: "Hlavní vlakové nádraží Liberec",
  coords: { lat: 50.7617544, lon: 15.0466081 },
  type: "train",
  group: "transportation",
};

export const busStation = {
  title: "Autobusové nádraží",
  desc: "Hlavní autobusové nádraží v Liberci",
  coords: { lat: 50.7633358, lon: 15.0476811 },
  type: "train",
  group: "transportation",
};


export const parkingForum = {
  title: "Parkovací dům nákupního centra Forum",
  desc: "Nejbližší parkoviště pro účastníky",
  coords: { lat: 50.7648083, lon: 15.0556956 },
  type: "location",
  group: "transportation",
};

export const parkingRakev = {
  title: "Parkoviště u KD pro novináře",
  desc: "Parkoviště vyhrazeno pro novináře a pozvané hosty",
  coords: { lat: 50.7649881, lon: 15.0535442 },
  type: "location",
  group: "transportation",
};

export const parkingHrazena = {
  title: "Parkoviště v ulici Hrazená",
  desc: "Parkoviště nedaleko místa pořádání zasedání CF",
  coords: { lat: 50.7665914, lon: 15.0526189 },
  type: "location",
  group: "transportation",
};


export const map = {
  center: { lat: 50.767, lon: 15.048, zoom: 15 },
  markers: [eventHall, workshopVenue, partyVenue, railwayStation, busStation, parkingForum, parkingRakev, parkingHrazena],
};

export const venues = [partyVenue, eventHall, workshopVenue];

export const nav = [
  //{
  //    link: "/registrace",
  //    title: "Registrace",
  //    notonhome: true,
  //  },
  {
    link: "/ubytovani",
    title: "Ubytování",
  },
  {
    title: "Zasedání CF",
    link: "/zasedani",
  },
  {
    title: "Stravování",
    link: "/stravovani",
  },
  {
    link: "/doprava",
    title: "Doprava",
  },
  {
    link: "/program",
    title: "Program",
  },
  {
    title: "Party",
    link: "/party",
  },
  {
    title: "Workshopy",
    link: "/workshopy",
  },
  {
    link: "/mapa",
    title: "Mapa",
  },
  {
    link: "/organizatori",
    title: "Organizátoři",
  },
//  {
//    link: "/volba-predsednictva",
//    title: "Volba předsednictva",
//  },
  {
    link: "/hendikepovani",
    title: "CF bez bariér",
  },
  {
    link: "/deti-a-zabava",
    title: "Děti a zábava",
  },
  {
      link: "/pro-dobrovolniky",
      title: "Dobrovolníci",
    },
];

export const organisers = [
  {
    title: "Organizační tým",
    members: [
      {
        name: "Hana Černošová",
        position: "Organizátor, hlavní koordinátorka CF 2023, koordinátorka dobrovolníků krajského sdružení LBK",
        phone: "+420770113645",
        email: "hana.cernosova@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/liberecky/img/people/hana-cernosova_2.jpg",
      },
      {
        name: "Marek Förster",
        position: "Web, registrace, technické zajištění, internetové připojení",
        phone: "+420775688305",
        email: "marek.forster@pirati.cz",
        avatarUrl:
          "/medailonky/marek-forster.jpg",
      },
      {
        name: "Zuzana Petrůvová",
        position: "Koordinátorka workshopů",
        phone: "+420778402477",
        email: "zuzana.petruvova@pirati.cz",
        avatarUrl:
        "/medailonky/zuzana-petruvova.jpg",
      },
      {
        name: "Andrej Ramašeuski",
        position: "Technické zajištění, pirátské systémy",
        phone: "+420605234197",
        email: "andrej.ramaseuski@pirati.cz",
        avatarUrl:
          "https://a.pirati.cz/crop/192x192/cf/2022/img/people/andrej-ramaseuski-2020.jpg",
      },
      {
        name: "Veronika Šmídová",
        position: "Styk s médii, tisková mluvčí",
        email: "veronika.smidova@pirati.cz",
        avatarUrl:
          "https://pbs.twimg.com/profile_images/1613986745007149062/avRl2-5h_400x400.jpg",
      },
      {
        name: "Jiří Kárský",
        position: "Finance, hospodář",
        email: "jiri.karsky@pirati.cz",
        phone: "+420735586424",
        avatarUrl:
          "/medailonky/unknown-pirate.jpg",
      },
      {
        name: "Marcel Kolaja",
        position: "Zástupce republikového předsednictva",
        email: "marcel.kolaja@pirati.cz",
        avatarUrl:
          "https://www.pirati.cz/media/images/32911304747_a0f6d63d01_o.0a7a869b.fill-480x480.jpg",
      },
      {
        name: "Blanka Charvátová",
        position: "Hlavní předsedající",
        email: "blanka.charvatova@pirati.cz",
        avatarUrl:
          "https://www.pirati.cz/media/images/blanka_charvatova_DDDcUSR.6bdda061.fill-480x480.png",
      },
      {
        name: "Vojtěch Pikal",
        position: "Hlavní zapisovatel, pořad zasedání",
        email: "Vojtech.Pikal@pirati.cz",
        phone: "+420778702714",
        avatarUrl:
          "/medailonky/vojtech-pikal.jpg",
      },
      {
        name: "Linda Kašpárková",
        position: "Koordinátorka činovníků, Stage manager",
        email: "linda.kasparkova@pirati.cz",
        phone: "+420608360206",
        avatarUrl:
          "https://lide.pirati.cz/media/cache/ef/ad/efad8f93796f4d3b9ee2b33d0f369c12.jpg",
      },

    ],
  },
];

export const accommodation = [
  {
    title: "Imperial Hotel Pytloun",
    link: "https://www.pytloungrandhotelimperial.cz/cs/",
    email: "event.coordinator@pytloun-hotels.cz",
    desc: "Luxusní a komfortní ubytování v nově zrekonstruovaném hotelu s restaurací na špičkové světové úrovni. Hotel poskytuje ubytování v pokojích kategorie Superior, Deluxe a Design.",
    coords: { lat: 50.764212858578944, lon: 15.050346117964015 },
    distance: 350,
    priceClass: "$$$",
    accessibility: {
      status: true,
      help: "Má bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 3150,
      },
      {
        bedCount: 2,
        price: 3150,
      },
      {
        bedCount: 4,
        price: 5250,
      },
    ],
  },
  {
    title: "Pytloun City Boutique Hotel - VYPRODÁNO",
    link: "http://www.pytlouncityboutiquehotel.cz/",
    email: "event.coordinator@pytloun-hotels.cz",
    desc: "Luxusní čtyřhvězdičkový hotel s moderním vybavením a designem, klimatizovanými pokoji a hlídaným krytým parkovištěm. K relaxaci a odpočinku je Vám v hotelu k dispozici relaxační centrum s nabídkou thajských masáží.",
    coords: { lat: 50.76644959387315, lon: 15.05883474187591 },
    distance: 450,
    priceClass: "$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 2,
        price: 2625,
      },
    ],
  },
  {
    title: "Pytloun Design Hotel",
    link: "http://www.pytloundesignhotel.cz/",
    email: "event.coordinator@pytloun-hotels.cz",
    desc: "Hotel v klidné části města nedaleko centra Liberce. Všechny pokoje hotelu s osobitým designem jsou nekuřácké. Přihlášení do hotelu a odhlášení z hotelu je bezkontaktní a prostřednistvím kiosku. Snídaně jsou formou bufetu.",
    coords: { lat: 50.73690300420853, lon: 15.052313688646306 },
    distance: 4000,
    priceClass: "$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 2,
        price: 2625,
      },
    ],
  },
  {
    title: "Pytloun Hotel Liberec",
    link: "http://www.pytlounhotelliberec.cz/",
    email: "event.coordinator@pytloun-hotels.cz",
    desc: "Elegantně a moderně zařízené pokoje. Součástí hotelu je zahradní terasa a dětské hřiště. Parkování u hotelu je zdarma. Přihlášení do hotelu a odhlášení z hotelu je bezkontaktní a prostřednistvím kiosku na místě ještě před Vaším příjezdem. Snídaně probíhají formou bufetu.",
    coords: { lat: 50.73690300420853, lon: 15.052313688646306 },
    distance: 3100,
    priceClass: "$$",
    accessibility: {
      status: false,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 2,
        price: 2625,
      },
    ],
  },
  {
    title: "Hotel Liberec - VYPRODÁNO",
    link: "https://www.hotel-liberec.eu/",
    email: "recepce@hotel-liberec.cz",
    desc: "Hotel v centru Liberce, ubytování v nově rekonstruovaných pokojích.",
    coords: { lat: 50.769920415129036, lon: 15.061138655972323 },
    distance: 950,
    priceClass: "$$",
    accessibility: {
      status: true,
      help: "Má bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 1450,
      },
      {
        bedCount: 2,
        price: 1990,
      },
    ],
  },
  {
    title: "Koleje Technické Univerzity Liberec",
    link: "https://koleje.tul.cz/",
    email: "ladislava.palkovicova@tul.cz",
    desc: "Levné ubytování na kolejích technické univerzity.",
    coords: { lat: 50.770487681548964, lon: 15.08657425849264 },
    distance: 3500,
    priceClass: "$",
    accessibility: {
      status: true,
      help: "Nemá bezbariérový přístup.",
    },
    variants: [
      {
        bedCount: 1,
        price: 380,
      },
      {
        bedCount: 2,
        price: 460,
      },
      {
        bedCount: 3,
        price: 770,
      },
    ],
  },
];

export const programGeneralSettings = {
  published: true,
};

export const program = {
  negotiation: {
    isUnderway: true,
    forumLink: "https://forum.pirati.cz/viewtopic.php?p=836020#p836020",
  },
  published: true,
  days: [
    {
      title: "pátek 28. 4. 2023",
      hourStart: 19,
      hourEnd: 30,
      // The smallest grid resolution in minutes. Take this into consideration when specifying time values.
      resolution: 15,
      tracks: [
        {
          code: "preparty",
          name: "Warm-up",
          venue: "Spot Klub Liberec",
          colorBg: "blue-300",
          colorFg: "white",
        },
        {
          code: "pre-party-nerds",
          name: "Warm-up pro nerdy",
          venue: "Kino Varšava",
          colorBg: "black",
          colorFg: "white",
        },
      ],
      slots: [
        {
          time: "19:00",
          sessions: [
            {
              track: "pre-party-nerds",
              timeStart: "19:00",
              timeEnd: "23:00",
              title: "Prepárty pro nerdy v Kině Varšava",
              agenda: [
                "Deskovky included"
              ],
            },
          ],
        },
        {
          time: "21:00",
          sessions: [
            {
              track: "preparty",
              timeStart: "21:00",
              timeEnd: "06:00",
              title: "Warm-up Párty ve Spotu",
              agenda: [
                "21.00 - 22.30	Beedix (breaks + house)",
                "22.30 - 00.00	Electro Druid (psy dance)",
                "00.00 - 01.30	Zdenek Key (techtrance)",
                "01.30 - 03.00	Cejn (psy/trance/techno)",
                "03.00 - 04.30	MARRA (techno)",
                "04.30 - 06.00	Punkie (dnb)"
              ],
            },
          ],
        },
      ],
    },
    {
      title: "sobota 29. 4. 2023",
      hourStart: 9,
      hourEnd: 24,
      // The smallest grid resolution in minutes. Take this into consideration when specifying time values.
      resolution: 5,
      tracks: [
        {
          code: "main",
          name: "Hlavní program",
          venue: "Hlavní sál Domu Kultury",
          colorBg: "blue-300",
          colorFg: "white",
        },
        {
          code: "voting",
          name: "Hlasování",
          venue: "Helios",
          colorBg: "black",
          colorFg: "white",
        },
        {
          code: "alternative",
          name: "Alternativní program",
          venue: "Malá scéna Domu Kultury",
          colorBg: "yellow",
          colorFg: "black",
        },
      ],
      slots: [
        {
          time: "09:00",
          sessions: [
            {
              track: "main",
              timeStart: "09:00",
              timeEnd: "10:00",
              title: "Ranní blok",
              agenda: [
                "9:00 - Zahájení (cca 15 minut)",
                "9:15 - Neschvalování Finanční zprávy CF [změna stanov] (15 minut)",
                "9:30 - Představení hostitelů - Libereckého kraje (5 minut)",
                "9:35 - Prezentace Mladé Pirátstvo (5 minut)",
                "9:40 - Prezentace Senioři na palubě (5 minut)",
              ],
            },
          ],
        },
        {
          time: "09:00",
          sessions: [
            {
              track: "alternative",
              timeStart: "09:00",
              timeEnd: "18:30",
              title: "Presscentrum - Vyhrazeno pro novináře",
              agenda: [
                "9.00 - 18.30	Prostor vyhrazen novinářům a tiskovému centru",
              ],
            },
          ],
        },
        {
          time: "10:00",
          sessions: [
            {
              track: "main",
              timeStart: "10:00",
              timeEnd: "12:00",
              title: "Dopolední blok",
              agenda: [
                "10:00 - Slavností zahájení",
                "10:00 - Hosté RP (pevně 10:00, 30 minut)",
                "10:35 - Změna článku 2 [změna stanov] (90 minut)",
              ],
            },
          ],
        },
        {
          time: "12:00",
          sessions: [
            {
              track: "main",
              timeStart: "12:00",
              timeEnd: "13:30",
              title: "Obědová pauza",
              agenda: [
                "Registrujte si oběd pomocí registračního formuláře k účasti na CF",
              ],
            },
          ],
        },
        {
          time: "13:30",
          sessions: [
            {
              track: "main",
              timeStart: "13:30",
              timeEnd: "16:00",
              title: "Odpolední blok I.",
              agenda: [
                "13:35 - Výsledky krajských radních a zastupitelů (45 minut, pořadí bude oznámeno)",
                "14:20 - Výběr vedoucích odborů ve výběrovém řízení [změna stanov] (50 minut)",
                "15:10 - Prezentace výsledků Vládního týmu (15 minut)",
                "15:25 - Prezentace výsledků Poslaneckého klubu (10 minut)",
                "15:35 - Prezentace výsledků Europoslaneckého klubu (10 minut)",
                "15:45 - Prezentace výsledků Senátorského klubu (10 minut)",
              ],
            },
          ],
        },
        {
          time: "16:00",
          sessions: [
            {
              track: "main",
              timeStart: "16:00",
              timeEnd: "17:30",
              title: "Odpolední blok II.",
              agenda: [
                "16:00 - Výroční zpráva předsedy (15 minut)",
                "16:15 - Shrnutí výsledků komunálních voleb (10 minut)",
                "16:25 - Informace o přípravě evropského programu (10 minut)",
                "16:35 - Prezentace Pirátského institutu (10 minut)",
                "16:45 - Přehled plnění úkolů Celostátního fóra (30 minut)",
              ],
            },
          ],
        },
        {
          time: "17:30",
          sessions: [
            {
              track: "main",
              timeStart: "17:30",
              timeEnd: "18:30",
              title: "Večerní blok",
              agenda: [
                "17:30 - Evropa po roce 2024 (60 minut) - Panelová diskuse, moderuje Marcel Kolaja",
                "Sven Clement - čestný předseda lucemburských pirátů, poslanec lucemburského parlamentu",
                "Emilija Stojmenova Duh - slovinská ministryně digitální tranformace",
                "Ivan Bartoš - předseda České pirátské strany, ministr pro místní rozvoj ČR",
                "",
                "18:00 - Zakončení zasedání CF",

              ],
            },
          ],
        },
        {
          time: "19:00",
          sessions: [
            {
              track: "main",
              timeStart: "19:00",
              timeEnd: "02:00",
              title: "Afterparty",
              agenda: [
                "19.00 - 20.35 Johnny Adela (nu-disco)",
                "20.30 - 22.00 Sanny (funky, deep house)",
                "22.00 - 23.30 Omnion (one-man-self-looping-orchestra, world-trance)",
                "23.30 - 00.30 Ivan Bartoš (psytrance)",
                "00.30 - 02.00 Cejn (psytrance)",
              ],
            },
            {
              track: "alternative",
              timeStart: "20:00",
              timeEnd: "21:30",
              title: "Debata - Návykové látky, legislativa",
              agenda: [
                "JUDr. Tereza Dleštíková, Ph.D.",
                "RNDr. Jiřina Macháčková, Ph.D.",
              ],
            },
            {
              track: "alternative",
              timeStart: "21:30",
              timeEnd: "01:00",
              title: "Chill-out zona, místo k relaxaci a pokecu",
              agenda: [
                "",
              ],
            },
          ],
        },
        {
          time: "09:30",
          sessions: [
            {
              track: "voting",
              timeStart: "09:30",
              timeEnd: "10:30",
              title: "Neschvalování Finanční zprávy CF",
              agenda: [
                "Hlasování - cca 9:30 až 10:30 (1 hodina)",
                "Vyhlášení výsledku - cca 10:40",
              ],
            },
            {
              track: "voting",
              timeStart: "12:10",
              timeEnd: "14:10",
              title: "Změna článku 2 - 1. kolo",
              agenda: [
                "Hlasování - cca 12:10 až 14:10 (2 hodiny)",
                "Vyhlášení výsledku - cca 14:30",
              ],
            },
            {
              track: "voting",
              timeStart: "15:10",
              timeEnd: "17:10",
              title: "Změna článku 2 - 2. kolo a Výběr vedoucích odborů ve výběrovém řízení - 1. kolo",
              agenda: [
                "Hlasování - cca 15:10 až 17:10 (2 hodiny)",
                "Vyhlášení výsledku - cca 17:30",
              ],
            },
            {
              track: "voting",
              timeStart: "17:30",
              timeEnd: "20:30",
              title: "Výběr vedoucích odborů ve výběrovém řízení - 2. kolo",
              agenda: [
                "Lze předpokládat, že rozhodnuto bude již v prvním kole a toto hlasování neproběhne",
                "Hlasování - cca 17:30 až 20:30 (3 hodiny)",
                "Vyhlášení výsledku - cca 20:45",
              ],
            },
          ],
        },

      ],
    },
    {
      title: "neděle 30. 4. 2023",
      hourStart: 9,
      hourEnd: 17,
      // The smallest grid resolution in minutes. Take this into consideration when specifying time values.
      resolution: 5,
      tracks: [
        {
          code: "workshopy_top",
          name: "Workshopy (Zasedací místnost)",
          venue: "Zasedací místnost v prvním patře Kina Varšava",
          colorBg: "blue-300",
          colorFg: "white",
        },
        {
          code: "workshopy_main",
          name: "Workshopy (Kinosál)",
          venue: "Kinosál Kina Varšava",
          colorBg: "blue-300",
          colorFg: "white",
        },
        {
          code: "workshopy_budvarka",
          name: "Workshopy (Budvarka)",
          venue: "Restaurace Budvarka, nám. Sokolovské 312/1",
          colorBg: "blue-300",
          colorFg: "white",
        },
      ],
      slots: [
        {
          time: "09:30",
          sessions: [
            {
              track: "workshopy_top",
              timeStart: "09:30",
              timeEnd: "10:15",
              title: "Pracovní skupina pro duševní zdraví: Duševní zdraví jako pirátská priorita",
              agenda: [
                "Situace v oblasti duševního zdraví je kritická, je nedostatečná síť služeb, které se starají o duševní zdraví, je nedostatek odborníků, zato je obrovské množství osob, které potřebují pomoc. Specificky špatná je oblast dětského duševního zdraví. Máme data, energii a víme, jak situaci řešit, teď to jen zavést do praxe. Nenechme lidi ve štychu.",
              ],
            },
            {
              track: "workshopy_top",
              timeStart: "10:25",
              timeEnd: "11:10",
              title: "Technický odbor: Úvod do stranických systémů a co jste o nich nevěděli, kybernetická bezpečnost",
              agenda: [
                "Špičky z IT oboru vás seznámí s našimi technickými systémy, pomouhou vám s vašimi problémy. Ukáží vám, co jste neznali a naučí vás, jak pohodlněji a efektivněji s nimi pracovat. Na tomto workshopu také bude možnost naučit se více o Linuxu, jak zabezpečit své zařízení nebo propojit identitu s dvoufázovým ověřením.",
              ],
            },
            {
              track: "workshopy_top",
              timeStart: "11:20",
              timeEnd: "12:00",
              title: "RT Zemědělství: Udržitelné veřejné stravování (bio, lokální, sezónní)",
              agenda: [
                "Ve veřejném stravování se v ČR vydá denně 2 miliony porcí. Jde o segment s obrovským potenciálem zajistit zdravé, udržitelné a přitom cenově dostupné stravování velké části obyvatelstva bez ohledu na jejich sociální postavení. Situaci nicméně komplikuje roztříštěnost agend mezi více resortů (MZ, MŽP, MZe, MŠMT, MMR...) i úrovní (stát, kraje, obce). Workshop by měl shrnout současný stav, poskytnout vodítka a příklady dobré praxe a umožnit diskusi nad překážkami.",
              ],
            },
          ],
        },
        {
          time: "09:30",
          sessions: [
            {
              track: "workshopy_main",
              timeStart: "09:30",
              timeEnd: "10:45",
              title: "RT Finance: Pirátský pohled na veřejné finance a národní hospodářství",
              agenda: [
                "Veřejné finance a národní hospodářství jsou průřezovým tématem. Nastavení daňové politiky, fiskální opatření jsou na každém kroku. V minulém roce jsme rozjeli velkou reformu dlouhodobého programu v oblasti veřejných financí a rádi bychom v tom pokračovali. A v rámci pokračování potřebujeme zpětnou vazbu, jak vnímáte veřejné finance jako Piráti. Těšíme se na vás na všechny!",
              ],
            },
            {
              track: "workshopy_main",
              timeStart: "10:55",
              timeEnd: "12:00",
              title: "RT Doprava: Doprava (nejen) pro Liberecký kraj",
              agenda: [
                "Současný stav a budoucí potřeby. Silniční a železniční spojení s ostatními kraji - pirátské priority.",
              ],
            },
          ],
        },
        {
          time: "12:00",
          sessions: [
            {
              track: "workshopy_top",
              timeStart: "12:00",
              timeEnd: "13:00",
              title: "Přestávka na oběd",
              agenda: [
                "V pauze na oběd lze využít restauraci s pizzerií Maškovka, restauraci Radniční sklípek, Kebab přímo naproti kinu Varšava nebo fastfood v nákupním centru Plaza.",
              ],
            },
          ],
        },
        {
          time: "12:00",
          sessions: [
            {
              track: "workshopy_main",
              timeStart: "12:00",
              timeEnd: "13:00",
              title: "Přestávka na oběd",
              agenda: [
                "V pauze na oběd lze využít restauraci s pizzerií Maškovka, restauraci Radniční sklípek, Kebab přímo naproti kinu Varšava nebo fastfood v nákupním centru Plaza.",
              ],
            },
          ],
        },
        {
          time: "13:00",
          sessions: [
            {
              track: "workshopy_top",
              timeStart: "13:00",
              timeEnd: "14:00",
              title: "RT Školství: Piráti a digitální vzdělávání v Evropském roce dovedností (2023)",
              agenda: [
                "Workshop zaměřený na rozvoj digitálních dovedností v celoživotní perspektivě, udržitelnou infrastrukturu škol, inovace ve vzdělávání, proměnu vzdělávání v kontextu rozvoje umělé inteligence. Co pro to jako Piráti můžeme udělat? Co pro to již děláme na národní a regionální úrovni?",
              ],
            },
            {
              track: "workshopy_top",
              timeStart: "14:10",
              timeEnd: "15:05",
              title: "Pracovní skupina Rovné šance: Obec otevřená nejen pro občany. Jak na to?",
              agenda: [
                "Žije se všem v našich obcích stejně dobře? Data z průzkumů ukazují, že ne. Městskou infrastrukturu využívají jinak rodiny s dětmi a jinak senioři. Ženy se cítí bezpečně a komfortně v jiném prostředí než muži. Co s tím? Města přívětivá ke všem existují! A řešení je snazší, než byste mysleli.",
              ],
            },
            {
              track: "workshopy_top",
              timeStart: "15:15",
              timeEnd: "16:10",
              title: "Meziresortní tým Návykové chování: Závislosti – od teorie k praxi",
              agenda: [
                "Na našem workshopu vás bude čekat krátká prezentace meziresortního týmu Návykové chování (představení týmu, obecné principy a krátký update z aktualit), po které bude následovat workshopový myšlenkový experiment, kde si budete moct vyzkoušet vymyslet, jak byste problematiku návykového chování řešili vy sami!",
              ],
            },
          ],
        },
        {
          time: "13:00",
          sessions: [
            {
              track: "workshopy_main",
              timeStart: "13:00",
              timeEnd: "14:00",
              title: "Panelová diskuse s představiteli evropských Pirátů [Debate with leaders of European Pirate Parties]",
              agenda: [
                "Debata s předsedy evropských pirátských stran – Lucembursko, Francie, Německo, Slovensko, Slovinsko. Kde jsme a čemu jako hnutí čelíme? Jak si můžeme vzájemně pomáhat? Jak si můžeme vzájemně pomáhat? V angličtině, moderuje Mikuláš Peksa.",
                "Anne Herpertz, chairwoman of the Pirate Party of Germany",
                "Florie Marie, spokesperson of French Pirates, chairwoman of Pirate Parties International",
                "Petra Kovačec, vice-president of the Pirate Party of Slovenia",
                "Zuzana Šubová, chairwoman of the Pirate Party of Slovakia",
                "Sven Clement, honorary chairman of the Pirate Party of Luxembourg",
              ],
            },
            {
              track: "workshopy_main",
              timeStart: "14:10",
              timeEnd: "15:10",
              title: "Tým z Evropského parlamentu: Evropský parlament: teoreticky i prakticky",
              agenda: [
                "Přijde ti Evropský parlamentu vzdálený, nesrozumitelný a moc složitý? Chceš se dozvědět víc a třeba za rok kandidovat ve volbách do EP? Přijď a zeptej se týmu, který zná EP zleva doprava i zprava do leva. Seminář s Tomášem Adamcem (politický poradce Marcela Kolaji) a Tomášem Polákem (PR specialista Pirátské delegace v EP) o fungování Parlamentu.",
              ],
            },
            {
              track: "workshopy_main",
              timeStart: "15:20",
              timeEnd: "16:10",
              title: "Centrální volební koordinátor: Volby 2024",
              agenda: [
                "Evropské, senátní a krajské volby jsou za rohem. Volební koordinátor Pavel Štěpánek nastíní, jak co nejlépe sladit přípravu na tyto tři stěžejní události příštího roku a uspět ve všech!",
              ],
            },
            {
              track: "workshopy_main",
              timeStart: "16:15",
              timeEnd: "17:00",
              title: "Mediální odbor Pirátské strany: Piráti držící prst na tepu doby",
              agenda: [
                "Časy se mění, s nimi i způsoby propagace a moderní formáty toho, jak se zarývat do hlav, myslí a srdcí veřejnosti a jak s ní komunikovat stěžejní témata. A nutné je se přizpůsobovat, umět vystihnout momentum, trefit se do nálady a správně používat nástroje, které používat můžeme. Pojďte si vyzkoušet workshop k formám propagace a k novému pirátskému designu! K dispozici bude také Miloslav Frič, autor nové grafické identity.",
              ],
            },
          ],
        },
        {
          time: "13:00",
          sessions: [
            {
              track: "workshopy_budvarka",
              timeStart: "13:30",
              timeEnd: "15:30",
              title: "RT Životní prostředí: Jak neničit obecní pole a lesy, (nejen) pro pirátské zastupitele a radní",
              agenda: [
                "Obce a města vlastní skoro půl milionu hektarů lesů a 200 000 hektarů zemědělské půdy. Změny klimatu a také poptávka společnosti po rozmanitější a bohatší přírodě jsou důvodem, proč se na své majetky začínají dívat jinak. Řada českých obcí přistupuje ke změnám různého druhu, ať už je to úprava pachtovních smluv nebo zavedení mezinárodních standardů přírodě blízkého lesnictví. Na workshopu rozebereme, jak by šlo na těchto tématech pracovat na radnici nebo v zastupitelstvu.",
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const partyBureauCandidates = [
  {
    postTitle: "Předsednický post",
    candidates: [
      {
        name: "Janka Michailidu",
        desc: "Rodačka z Petrovic u Karviné a postgraduální studentka VŠCHT, je dlouholetou členkou Pirátů, aktuálně je členkou republikového výboru a členkou správní rady nově založeného Pirátského institutu Pí. Zastávala pozici místopředsedkyně strany, krátkodobě v roce 2014 i pozici předsedkyně Pirátů. Podílela se na programových postojích Pirátů v oblasti psychotropních látek. Jejím cílem je, aby Piráti vyšli z nárazu do reality silnější než dřív a pokusili se nalézt syntézu mezi idealismem a pragmatismem.",
        link: "https://forum.pirati.cz/viewtopic.php?p=786120#p786120",
        img: "janka-michailidu.jpg",
      },
      {
        name: "Ivan Bartoš",
        desc: "Doktor informační vědy z FFUK, informační architekt, jeden ze zakládajících členů Pirátské strany, hudebník, čtenář, hráč deskových her a dlouholetý předseda Pirátské strany Ivan Bartoš. Jako poslanec v minulém volebním období předsedal sněmovnímu výboru pro regionální rozvoj. Spolu s oblastí digitalizace se nyní bude agendě místního rozvoje věnovat coby ministr a místopředseda nastupující vlády. Piráti jsou podle něj liberální středovou stranou s kvalitním programem bránícím svobodu lidí, vzdělanost a soudržnost společnosti. Politika je prostředkem změny, nikoliv cílem.",
        link: "https://forum.pirati.cz/viewtopic.php?p=786951#p786951",
        img: "ivan-bartos.jpg",
      },
      {
        name: "Lukáš Wagenknecht",
        desc: "Současný senátor za Prahu 8 a 18, ekonom, auditor a vysokoškolský lektor je aktivním bojovníkem proti korupci a finančním machinacím. V roce 2015 obdržel Cenu za odvahu od Nadačního fondu proti korupci za své dlouhodobé nekompromisní postoje v roli auditora, když ukázal na nesrovnalosti v Dopravním podniku v Praze nebo na Ministerstvu financí. Zastává postoj, že správnou cestou v politice je být blízko občanům, jít mezi lidi, bavit se s nimi a naslouchat jejich názorům. Uvnitř strany chce spojovat různé myšlenkové směry.",
        link: "https://forum.pirati.cz/viewtopic.php?p=785845#p785845",
        img: "lukas-wagenknecht.jpg",
      },
      {
        name: "David Witosz ",
        desc: "Místostarosta MO Moravská Ostrava a Přívoz, radní města Ostravy a garant programového bodu doprava pro volby do poslanecké sněmovny 2017 a 2021. V rámci úkolů ve vedení městského obvodu jsou jeho prioritami obnova a revitalizace bytového a nebytového fondu, lepší kultivace veřejného prostoru z pohledu komunálního úklidu, řešení problematiky statické dopravy a nové kontrolní metody ve stavebních investicích. Budoucí předseda Pirátů by podle něj měl splnit dva základní politické axiomy: Strana Pirátů se nestane stranou jednoho muže a naučí se používat slovo NE.",
        link: "https://forum.pirati.cz/viewtopic.php?p=788403#p788403",
        img: "david-witosz.jpg",
      },
    ],
  },
  {
    postTitle: "Místopředsednický post",
    candidates: [
      {
        name: "Jiří Snížek",
        desc: "Vystudovaný elektroinženýr a filosof, zastupitel Středočeského kraje a náměstek hejtmanky pro regionální rozvoj a územní plánování pochází ze Záboří nad Labem a nyní žije v Kolíně. Mezi lety 2018 a 2020 působil jako předseda středočeských Pirátů. Z pozice místopředsedy chce zajistit víc zdrojů pro prezentaci místních sdružení a jejich častější prezentaci v centrálních komunikačních kanálech a také transparentnější fungování odborného zázemí strany.",
        link: "https://forum.pirati.cz/viewtopic.php?p=783216#p783216",
        img: "jiri-snizek.jpg",
      },
      {
        name: "Blanka Charvátová",
        desc: "Jednatelka republikového výboru, předsedkyně místního sdružení Piráti Praha 21 a zastupitelka tamtéž. Momentálně například pracuje jako vedoucí pražského dopravního týmu na přípravě programu pro pražské magistrátní volby v roce 2022. Jako členka republikového předsednictva by se ráda zasadila o zlepšení komunikace Pirátů dovnitř i navenek. Chce, aby Piráti začali být pro voliče čitelnou stranou a aby se naučili s voliči mluvit jasně a srozumitelně.",
        link: "https://forum.pirati.cz/viewtopic.php?p=783987#p783987",
        img: "blanka-charvatova.jpg",
      },
      {
        name: "Hana Hajnová",
        desc: "Již během studia sociologie a evropských studií působila jako projektová manažerka a pomáhala rozvíjet inovační ekosystém na jižní Moravě. Po návratu na Vysočinu se aktivně od roku 2014 angažuje u Pirátů, od kapitánky dračích lodí po první náměstkyni hejtmana pro regionální rozvoj. V republikovém předsednictvu se chce zaměřit na snížení administrativy, zlepšení vnitřní komunikace a rozvoj lidských zdrojů, protože lidé a čas, který jsou ochotni Pirátům věnovat, je to nejcennější, co máme.",
        link: "https://forum.pirati.cz/viewtopic.php?p=785220#p785220",
        img: "hana-hajnova.jpg",
      },
      {
        name: "Sámir Shanaáh",
        desc: "Absolvent oboru podnikání a managementu a nynější student psychologie managementu v současnosti profesně působí jako obchodní manažer společnosti. Je koordinátorem fundraisingu Pirátů a v Berouně je předsedou místního sdružení. Mezi jeho priority v místopředsednické funkci patří udržení pirátských hodnot, zefektivnění vnitřních procesů, členský růst strany a restrukturalizace organizační struktury.",
        link: "https://forum.pirati.cz/viewtopic.php?f=350&t=59342",
        img: "samir-shanaah.jpg",
      },
      {
        name: "Marcel Kolaja",
        desc: "Produktový manažer, odborník na informační technologie a dlouhodobý člen Pirátů je od roku 2019 europoslancem a místopředsedou Evropského parlamentu. Ve svém politickém působení se zaměřuje na otevřené technologie, zachování svobody na Internetu, ochranu nezávislosti médií a transparentní a sjednocenou Evropu. Mezi zásady, které chce v předsednictvu Pirátů reprezentovat patří svoboda informací a ochrana občanských svobod a práv, zejména pak práva na soukromí. Sociálně-liberální politika Pirátů má myslet na budoucnost a nenechat lidi na pospas ani zvůli státu, ani zájmům nadnárodních korporací.",
        link: "https://forum.pirati.cz/viewtopic.php?f=350&t=59473",
        img: "marcel-kolaja.jpg",
      },
      {
        name: "Jana Koláříková",
        desc: "Dlouhodobá spolupracovnice a členka Pirátů působila jako koordinátorka dobrovolníků pro Jihočeský kraj a vedoucí Personálního odboru Pirátů. Může nabídnout také své zkušenosti s mezinárodní neziskovou pomocí v oblasti vzdělávání, zkušenosti s rozvojovými projekty nebo redaktorskou praxí. Na pozici místopředsedkyně chce klást důraz na kulturu komunikace a důvěry uvnitř strany, efektivní zapojení nových členů, rozvoj kompetencí potřebných pro výkon politických funkcí a podporu regionů z centra. Piráty vidí jako aktivní a sebevědomou stranu s vlastními politickými tématy.",
        link: "https://forum.pirati.cz/viewtopic.php?p=783766#p783766",
        img: "jana-kolarikova.jpg",
      },
      {
        name: "Vojtěch Lukavec",
        desc: "Student všeobecného lékařství působí jako člen Resortního týmu Zdravotnictví a místopředseda Mladého Pirátstva. Je členem zdravotního výboru Středočeského kraje a má profesní zkušenosti jako školitel primární prevence a zdravotní bratr na ARO. Zabývá se epidemiologickými tématy, preventivní medicínou a pracovními podmínkami zdravotnického personálu. Za klíčové úkoly nového vedení považuje zlepšení komunikace směrem k médiím, jednotné a koordinované vystupování a návrat k liberálně progresivní politické suverenitě strany.",
        link: "https://forum.pirati.cz/viewtopic.php?p=786070#p786070",
        img: "vojtech-lukavec.jpg",
      },
      {
        name: "Filip Křistek",
        desc: "Člen meziresortního týmu Návykové chování a Krajského expertního týmu pro bezpečnost je zároveň studentem oboru Evropská diplomacie a Politologie. Ve své práci pro Piráty se dosud zabýval převážně marketingem v oblasti sociálních sítí, grafikou i celkovým chodem organizace. Jeho vize spočívá v návratu k původním hodnotám a kořenům Pirátů jako jsou legalizace, transparentní stát a společenská progrese. Uvnitř strany chce prosazovat zapojení mladší generace, lepší informovanost členstva a efektivnější spolupráci mezi krajem a centrální úrovní.",
        link: "https://forum.pirati.cz/viewtopic.php?p=785576#p785576",
        img: "filip-kristek.jpg",
      },
      {
        name: "Martin Jiránek",
        desc: "Analytik a marketingový poradce firem byl během minulého volebního období poslancem a do roku 2019 též zastupitelem města Trutnov. V rámci poslaneckého klubu byl osobou zodpovědnou za oblast průmyslu a obchodu, telekomunikací a cestovního ruchu. Piráty chce vidět jako úspěšnou, pevně ukotvenou a pro voliče čitelnou středovou stranu. V republikovém předsednictvu chce proto řešit ujasnění si základních pirátských a politických hodnot, tedy jakou stranou v jakém státě chceme být, a k tomu přidat chytrou komunikaci ke členům i ven bez zbytečných střílení se do nohy.",
        link: "https://forum.pirati.cz/viewtopic.php?p=786044#p786044",
        img: "martin-jiranek.jpg",
      },
      {
        name: "Petr Kapounek",
        desc: "Prostějovský zastupitel a předseda komise IT a Smart Region v Olomouckém kraji je povoláním projektový manažer v telekomunikační společnosti. Považuje se za libertariána a chce proto Česko směřovat k vzdělanostní společnosti a hodnotám svobody založeným na osobní odpovědnosti. Chce, aby Piráti přispívali k vytváření podmínek pro průhledný a efektivní stát bez šikany a ČR se tak mohla stát ekonomickým tygrem. Nechce, aby Piráti byli stranou vyššího dobra, která lidi převychovává pomocí omezení, nařízení a zákazů.",
        link: "https://forum.pirati.cz/viewtopic.php?f=350&t=59255",
        img: "kapounek-petr.jpg",
      },
      {
        name: "Antonino Milicia",
        desc: "Odborník se vzděláním a praxí v oblasti strategie, projektů a organizačního rozvoje. Piráti jsou pro něj jediná strana poctivé a autentické politiky, která se však musí poučit ze svých chyb. Své hlavní poslání vidí v nastavení strategického směřování a rozvoje strany, srozumitelné komunikaci programu a zlepšení vnitřní kultury a demokracie. Chce také usilovat o snížení byrokracie, vyšší akceschopnost vedení a účinnější zapojení členů. Jeho osobní vizí je pomoci z Pirátů vytvořit dobře fungující organismus, kde každý může být užitečný a s radostí pomáhat měnit svět k lepšímu.",
        link: "https://forum.pirati.cz/viewtopic.php?p=786036#p786036",
        img: "antonino-milicia.jpg",
      },
      {
        name: "Lucie Cirkva Chocholová",
        desc: "Zastupitelka Středočeského kraje a předsedkyně Výboru pro památkovou péči, kulturu a cestovní ruch má přes dvacet let praxe v kultuře jak v soukromé, tak i veřejné sféře, včetně práce na Ministerstvu kultury. Piráty vnímá jako politickou sílu, která může navrátit důvěru lidí v politiku pomocí aplikace demokratických principů a zaměření na odbornost. Jejím cílem je reflektování současné liberální politiky a navázání na jádrová témata Pirátů. Chce co nejlépe využít lidský potenciál a zamezit vzniku osobních kultů.",
        link: "https://forum.pirati.cz/viewtopic.php?p=785404#p785404",
        img: "lucie-chocholova.jpg",
      },
      {
        name: "Ondřej Profant",
        desc: "Dřívější poslanec a zastupitel hlavního města Prahy se po dobu svého politického působení zaměřuje na témata spojená s digitalizací a zavádění open source softwaru. Je aktivní i prostřednictvím spolku Otevřená města, zabývá se svobodnou kulturou a stál za pražským projektem otevřených dat. Na pozici místopředsedy chce přispět k vyřešení vnitrostranických sporů a budování značky Pirátů. Zároveň vidí jako jednu z výzev efektivní předávání vědomostí a schopností nových členů/členek a kandidátů/kandidátek.",
        link: "https://forum.pirati.cz/viewtopic.php?p=785610#p785610",
        img: "ondrej-profant.jpg",
      },
      {
        name: "Jana Skopalíková",
        desc: "Místopředsedkyně krajského sdružení Středočeský kraj a současně radní pro životní prostředí a zemědělství Středočeského kraje. Vystudovaná botanička se zaměřuje na činnosti spojené s ochranou životního prostředí a sociální pomoc, jako jsou akce Ukliďme Česko nebo Pirátský guláš. V republikovém předsednictvu by chtěla přispět k větší mediální podpoře krajských a regionálních osobností, podpoře a školení s výhledem na komunální volby. Plánuje také lepší práci s nově přijatými členy.",
        link: "https://forum.pirati.cz/viewtopic.php?p=788454#p788454",
        img: "jana-skopalikova.jpg",
      },
      {
        name: "Dalibor Záhora",
        desc: "Zakládající člen Pirátů, který se již od devadesátých let věnuje nezávislým kulturním i politickým aktivitám, spolupracuje i s nevládními organizacemi bránícími práva spoluobčanů. Stál při vzniku Pirátských novin a podílel se na budování středočeské organizace Pirátů. Inicioval také kandidaturu senátorky Adély Šípové. Jako zásadní problém, který chce řešit, vidí centralizaci strany, jíž chce čelit návratem rozhodovacích pravomocí do stranické struktury. Chce zajistit, aby nebyla omezována práva řadových členů a členek a byla brána vedením strany na vědomí.",
        link: "https://forum.pirati.cz/viewtopic.php?p=787584#p787584",
        img: "dalibor-zahora.jpg",
      },
      {
        name: "Jiří Knotek",
        desc: "Absolvent Politologie a Evropských studií na Univerzitě Palackého v Olomouci se s Piráty účastnil několika předvolebních kampaní. Aktuálně je zlínským krajským zastupitelem a předsedou výboru pro kulturu a památky. Předmětem jeho zájmu je zahraniční a bezpečnostní politika ČR a mezinárodní vztahy. Jako místopředseda se chce aktivně zapojit do komunikace a reprezentace Pirátů navenek a pomáhat kandidátům do komunálních voleb s kampaní a konzultovat předvolební kroky.",
        link: "https://forum.pirati.cz/viewtopic.php?p=784663#p784663",
        img: "jiri-knotek.jpg",
      },
      {
        name: "Martin Kučera",
        desc: "Zastupitel městského obvodu Moravská Ostrava a Přívoz je dlouholetým Pirátem, dřívějším místopředsedou nebo vedoucím administrativniho odboru. Je vystudovaný matematický analytik, v legislativě ho zajímají především oblasti související s tvůrčí činností a jejími dopady na školství či zdravotnictví, věnuje se též problematice internetu či stavu životního prostředí. V předsednictvu chce hájit principy kontroly práce funkcionářů, ochranu soukromí občanů, včetně obhajoby svobody na internetu proti cenzuře. Chce dosáhnout revize kopírovacího monopolu v podmínkách 21. století a svobody pěstování a zpracování konopí.",
        link: "https://forum.pirati.cz/viewtopic.php?p=787040#p787040",
        img: "martin-kucera.jpg",
      },
      {
        name: "Šárka Václavíková",
        desc: "Předsedkyně Young Pirates of Europe působila jako koordinátorka kampaně pro komunální volby 2018 nebo jako volební manažerka Jiřího Kadeřávka. V Moravskoslezském kraji byla koordinátorkou pro krajské volby 2020, mimo to se věnovala občanskému aktivismu. V současnosti je asistentkou pražského radního Víta Šimrala. Jako místopředsedkyně bude klást důraz na rovné šance a práva, svobodu pro všechny bez rozdílu, legalizaci a transparenci. Též stojí o lepší mezinárodní propojení Pirátů.",
        link: "https://forum.pirati.cz/viewtopic.php?p=788634#p788634",
        img: "sarka-vaclavikova.jpg",
      },
    ],
  },
];
