
import Button from "components/Button";
import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

const KidsnFun = () => {
  return (
    <article className="w-full">
      <PageTitle>Děti a zábava pro malé i velké</PageTitle>

      <Helmet>
        <title>Děti a zábava pro malé i velké | {basics.appTitle}</title>
        <meta property="og:title" content="Děti a zábava pro malé i velké" />
      </Helmet>

      <div className="content-block">
        <p>
        A co děti, mají si kde hrát?<br/>
        Samozřejmě! Jako Piráti chceme jít příkladem, a proto jsme připravili několik možností, jak můžete zabavit své děti po dobu konání celostátního fóra a&nbsp;přesto nebýt ochuzeni o&nbsp;nabitý program.<br/>
        Připravili jsme několik možností, jak můžete s&nbsp;dětmi strávit hezký čas v&nbsp;Liberci nebo nechat náš tým, aby jim připravil zábavný den.
        </p>

        <h3 className="head-alt-md">DĚTSKÝ KOUTEK</h3>
        <p>
        Pro děti od věku cca 4 let jsme na hlavní sobotní část programu zajistili hlídání pirátkami <a href="https://lide.pirati.cz/profil/2212/" target="_blank" rel="noopener noreferrer">Berenikou</a>, <a href="https://lide.pirati.cz/profil/2696/" target="_blank" rel="noopener noreferrer">Helenou</a> a&nbsp;<a href="https://lide.pirati.cz/profil/3469/" target="_blank" rel="noopener noreferrer">Danielou</a>.
        Pro dětské návštěvníky je vyhrazena vybavená hrací část, stranou od hlavního programu. Zde budou k dispozici různé hračky, omalovánky, relaxační koutek anebo kreativní dílny pro starší děti a&nbsp;další.<br/>
        </p>

        <h4 className="head-alt-sm">Loutkové divadlo</h4>
        <p>
        Pro malé i velké diváky připravujeme loutkové představení. Vyhlášená Daniela Weissová z Turnova, divadelnice, zdravotní klaun a v neposlední řadě 1. místopředsedkyně krajského sdružení Pirátů, nabídne dokonce výběr ze dvou představení - <strong>Čert to vzal</strong> a <strong>Vávra má rád hasiče</strong>.
        </p>

        <h4 className="head-alt-sm">Promítání pohádek</h4>
        <p>
        Chcete pustit pohádku? Není problém, ozvalo se ze sluchátka. Až vás přestane bavit kreslení či hraní, pustíme pohádku na plátno. Skoro jako v kině. (Jen nemáme popcorn!)
        </p>
        <h4 className="head-alt-sm">Zdravé svačinky</h4>
        <p>
        Dopoledne a odpoledne na stůl postavíme mísu plnou ovoce a zeleniny. Takže se nemusíte obávat, že budou mít hlad jako vlci. Vlci totiž ovoce a zeleninu nejí.
        </p>

        <p>
        <strong>Nejsme žádná profesionální hlídací agentura, proto prosím berte vždy ohled na věk a&nbsp;aktuální potřeby vašich dětí.</strong><br/>
        </p>
        <p>
        Pro rodiče, kteří budou v&nbsp;dětské části, bude instalována TV s&nbsp;přenosem z&nbsp;hlavního sálu.
        </p>

        <h3 className="head-alt-md">PIRÁTSKÁ STOPOVAČKA PO PAMĚTIHODNOSTECH LIBERCE</h3>
        <p>
        Vydejte se po stopách naší pirátské hry a&nbsp;dozvíte se zábavnou formou něco o&nbsp;historickém Liberci.
        Jednoduše vyražte ven, vyfoťte se na níže uvedených místech a&nbsp;užijte si offline čas se svými blízkými. 
        Zvítězí ti, kteří se na stopovačce nejoriginálněji nafotí a&nbsp;odborná porota jejich úlovky náležitě vyhodnotí. Své fotky zasílejte na e-mail <a href="mailto:zuzana.petruvova@pirati.cz">zuzana.petruvova@pirati.cz</a>.
        Pro každého účastníka bude poté připravená sladká odměna a&nbsp;diplom v&nbsp;kině Varšava (v&nbsp;neděli 10 - 17 hodin, hlaste se organizátorům).
        </p>
        <ul>
            <li>Šolcův dům - <a href="https://pi2.cz/cipd" target="_blank" rel="noopener noreferrer">https://pi2.cz/cipd</a></li> 
            <li>Kanálová ulička - <a href="https://pi2.cz/pbrk" target="_blank" rel="noopener noreferrer">https://pi2.cz/pbrk</a></li>
            <li>Budova spořitelny Liberec - <a href="https://pi2.cz/sowt" target="_blank" rel="noopener noreferrer">https://pi2.cz/sowt</a></li>
            <li>Liberecká radnice - <a href="https://pi2.cz/amgi" target="_blank" rel="noopener noreferrer">https://pi2.cz/amgi</a></li>
            <li>Meteorologický sloup - <a href="https://pi2.cz/nroy" target="_blank" rel="noopener noreferrer">https://pi2.cz/nroy</a></li>
            <li>Divadlo F. X. Šaldy - <a href="https://pi2.cz/rwdu" target="_blank" rel="noopener noreferrer">https://pi2.cz/rwdu</a></li> 
            <li>Kostel sv. Antonína Velikého - <a href="https://pi2.cz/idkw" target="_blank" rel="noopener noreferrer">https://pi2.cz/idkw</a></li>
            <li>Valdštejnské domky - <a href="https://pi2.cz/frbn" target="_blank" rel="noopener noreferrer">https://pi2.cz/frbn</a></li> 
            <li>Zámek Liberec - <a href="https://pi2.cz/mzix" target="_blank" rel="noopener noreferrer">https://pi2.cz/mzix</a></li>
            <li>Vila Johanna Liebiega mladšího - <a href="https://pi2.cz/yiem" target="_blank" rel="noopener noreferrer">https://pi2.cz/yiem</a></li>
            <li>Památník obětem komunismu - <a href="https://pi2.cz/sprg" target="_blank" rel="noopener noreferrer">https://pi2.cz/sprg</a></li>
            <li>Liebigova vila - <a href="https://pi2.cz/eojv" target="_blank" rel="noopener noreferrer">https://pi2.cz/eojv</a></li>
            <li>Severočeské muzeum - <a href="https://pi2.cz/yskp" target="_blank" rel="noopener noreferrer">https://pi2.cz/yskp</a></li> 
            <li>Městský dům - Bývalá obchodní komora - <a href="https://pi2.cz/mtrv" target="_blank" rel="noopener noreferrer">https://pi2.cz/mtrv</a></li>
            <li>Městské lázně - Oblastní galerie Liberec - <a href="https://pi2.cz/vboj" target="_blank" rel="noopener noreferrer">https://pi2.cz/vboj</a></li>
        </ul>
      </div>

      <Button
              className="md:text-xl btn--to-blue-300 mt-8 mb-8"
              bodyClassName="whitespace-no-wrap"
              color="black"
              icon="ico--chevron-right"
              href="https://www.google.com/maps/d/edit?mid=1AJ-KfHkJVSmDs_OkFm7F3gfOsrX_6zI&usp=sharing"
              target="_blank"
            >
              Mapa stopovačky 
      </Button>

      <div className="content-block">

        <h3 className="head-alt-md">KRYTÁ ZÁBAVA V LIBERCI</h3>
        <p>
        Nedaleko Domu Kultury Liberec najdete hned několik zajímavých atrakcí, kde si vaše ratolesti (malé i velké) užijí spoustu zábavy.
        </p>
        <ul>
            <li>iQlandia Liberec - <a href="https://iqlandia.cz/" target="_blank" rel="noopener noreferrer">https://iqlandia.cz/</a></li>
            <li>Babylon - <a href="https://www.centrumbabylon.cz/cs/zabava/a-1/" target="_blank" rel="noopener noreferrer">https://www.centrumbabylon.cz/cs/zabava/a-1/</a> <i>nedoporučujeme, pokud nechcete utratit mnoho peněz a ocitnout se v dětské džungli</i></li>
            <li>Zoo Liberec - <a href="https://www.zooliberec.cz/" target="_blank" rel="noopener noreferrer">https://www.zooliberec.cz/</a></li>
            <li>Plavecký bazén - <a href="https://bazen.liberec.cz/" target="_blank" rel="noopener noreferrer">https://bazen.liberec.cz/</a></li>
            <li>Botanická zahrada - <a href="https://www.botaniliberec.cz/" target="_blank" rel="noopener noreferrer">https://www.botaniliberec.cz/</a></li>
            <li>Kino Cinestar v OC Fórum - <a href="https://www.cinemacity.cz/cinemas/liberec/" target="_blank" rel="noopener noreferrer">https://www.cinemacity.cz/cinemas/liberec/</a></li>
            <li>DinoPark v OC Plaza - <a href="https://www.dinopark.cz/cz/liberec" target="_blank" rel="noopener noreferrer">https://www.dinopark.cz/cz/liberec</a></li>
            <li>Hop Aréna v OC Severka - <a href="https://hoparena.cz/" target="_blank" rel="noopener noreferrer">https://hoparena.cz/</a></li>
        </ul>

        <h3 className="head-alt-md">DĚTSKÁ VENKOVNÍ HŘIŠTĚ</h3>
        <p>
        Doporučujeme také návštěvu dětských venkovních hřišť, kde si malá i velká Pirátčata mohou zaskotačit:
        </p>
        <ul>
            <li>Trampolínové hřiště Nisa park - <a href="https://www.nisapark.cz/" target="_blank" rel="noopener noreferrer">https://www.nisapark.cz/</a></li>
            <li>Zámecký Lesopark Vratislavice nad Nisou - <a href="https://goo.gl/maps/SW5ixEiwd7qRSnCaA" target="_blank" rel="noopener noreferrer">https://goo.gl/maps/SW5ixEiwd7qRSnCaA</a></li>
            <li>Velké hřiště Dětský koutek v Lidových sadech - <a href="http://www.lidovesadyliberec.cz/cz/detsky-koutek-v-lidovych-sadech" target="_blank" rel="noopener noreferrer">http://www.lidovesadyliberec.cz/cz/detsky-koutek-v-lidovych-sadech</a></li>
            <li>Ještěd - <a href="https://www.rozhlednajested.cz/" target="_blank" rel="noopener noreferrer">https://www.rozhlednajested.cz/</a> <i>pozor, lanovka není v provozu</i></li>
        </ul>


      </div>
    </article>
  );
};

export default KidsnFun ;
