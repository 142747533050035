import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import styled from "styled-components";

import Button from "components/Button";
import {
  Card,
  CardActions,
  CardBody,
  CardBodyText,
  CardHeadline,
} from "components/cards";
import PageTitle from "components/PageTitle";
import { basics, program } from "config";

// @credits: https://css-tricks.com/building-a-conference-schedule-with-css-grid/

const buildTimeSlots = (hourStart, hourEnd, resolution) => {
  const timeSlots = [];

  Array(hourEnd - hourStart)
    .fill(hourStart)
    .forEach((hour, i) => {
      const slotHour = (hour + i).toString().padStart(2, "0");
      let minutes = 0;

      while (minutes < 60) {
        timeSlots.push(slotHour + minutes.toString().padStart(2, "0"));

        minutes += resolution;
      }
    });

  return timeSlots.map((slot) => `[time-${slot}] 0.25fr`).join(" ");
};

const buildTracks = (trackList) => {
  const tracks = trackList.map((track, idx) =>
    idx === 0
      ? `[track-${track.code}-start] 1fr`
      : `[track-${trackList[idx - 1].code}-end track-${track.code}-start] 1fr`
  );

  tracks.push(`[track-${trackList[trackList.length - 1].code}-end]`);

  return tracks.join(" ");
};

const Schedule = styled.div`
  @media screen and (min-width: 992px) {
    display: grid;
    grid-column-gap: 1rem;
    grid-template-rows:
      [tracks] auto
      ${(props) =>
        buildTimeSlots(props.hourStart, props.hourEnd, props.resolution || 30)};
    grid-template-columns:
      [times] 3em
      ${(props) => buildTracks(props.tracks)};
  }

  ${(props) =>
    props.tracks
      .map(
        (track) => `
    .track-${track.code} {
      position: relative;
      color: var(--color-${track.colorFg});
    }

    .track-${track.code}::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--color-${track.colorBg});
      z-index: -1;
    }
  `
      )
      .join(" ")}
`;

const TimeSlot = styled.h2`
  grid-row: time-${(props) => props.time};
  grid-column: times;

  @media screen and (max-width: 991px) {
    padding: 1rem 0;
  }
`;

const TrackSlot = styled.div`
  grid-row: tracks;
  grid-column: track-${(props) => props.track};
  display: none;

  @supports (display: grid) {
    @media screen and (min-width: 992px) {
      & {
        display: block;
        padding: 0.5rem;
        position: sticky;
        top: 0;
        z-index: 1000;
        background-color: rgba(255, 255, 255, 0.9);
      }
    }
  }
`;

const Session = styled.div`
  grid-column: track-${(props) => props.track};
  grid-row: time-${(props) => props.timeStart} / time-${(props) =>
      props.timeEnd};
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  min-height: 4em;

  @supports (display: grid) {
    @media screen and (min-width: 992px) {
      margin-bottom: 1rem;
    }
  }
`;

const Program = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [currentSession, setCurrentSession] = useState(null);

  const showSessionDetail = (evt, session) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();

    setCurrentSession(session);
    setShowDetail(true);
  };

  const hideSessionDetail = () => {
    setShowDetail(false);
    setCurrentSession(null);
  };

  const handleEsc = useCallback((event) => {
    if (event.keyCode === 27) {
      hideSessionDetail();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleEsc, false);

    return () => {
      document.removeEventListener("keydown", handleEsc, false);
    };
  }, [handleEsc]);

  return (
    <article className="space-y-8 w-full">
      <PageTitle>Program</PageTitle>
      <Helmet>
        <title>Program | {basics.appTitle}</title>
        <meta property="og:title" content="Mapa" />
      </Helmet>

      {program.negotiation.isUnderway && (
        <div className="content-block">
          <p>
            O sobotním pořadu zasedání se jednalo na foru. Více informací
            získáte na{" "}
            <a
              href={program.negotiation.forumLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              Pirátském fóru v příslušném vlákně
            </a>
            .
            <br/>
            V pátek večer proběhne Warm-Up Párty v klubu Spot 
              - <a
              href="https://www.facebook.com/events/931574544927872/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Pirate Electronic Party (Událost na FB)
            </a>.
            <br/>
            Pro nerdy bude v pátek večer už od 19.00 připraveno zázemí pro pokec a hraní deskovek v Kině Varšava.<br/>
            V neděli po celý den budou probíhat tématické workshopy v Kině Varšava.<br/><br/>
            Informace průběžně doplňujeme a aktualizujeme.
          </p>
        </div>
      )}

      {program.published &&
        program.days.map((programDay) => (
          <div key={programDay.title} id={programDay.title}>
            <h2 className="head-alt-md md:mb-4">{programDay.title}</h2>

            <div
              className={classNames("modal__overlay", {
                hidden: !showDetail || !currentSession,
              })}
              onClick={hideSessionDetail}
              style={{ zIndex: "9997" }}
            >
              <div className="modal__content" role="dialog">
                <div
                  className="modal__container w-full max-w-md"
                  role="dialog"
                  style={{ zIndex: "9998" }}
                >
                  <div
                    className="modal__container-body elevation-10"
                    style={{ zIndex: "9999" }}
                    onClick={(evt) => {
                      evt.stopPropagation();
                    }}
                  >
                    {currentSession && (
                      <Card>
                        <CardBody>
                          <CardHeadline>{currentSession.title}</CardHeadline>
                          <CardBodyText className="content-block">
                            {currentSession.link && (
                              <p>
                                <a
                                  href={currentSession.link}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  Další informace
                                </a>
                              </p>
                            )}
                            {currentSession.description && (
                              <p>{currentSession.description}</p>
                            )}
                            {currentSession.agenda && (
                              <>
                                <h4>Agenda</h4>
                                <ul>
                                  {currentSession.agenda.map((agenda, idx) => (
                                    <li key={idx}>{agenda}</li>
                                  ))}
                                </ul>
                              </>
                            )}
                          </CardBodyText>
                        </CardBody>
                        <CardActions>
                          <Button
                            color="blue-300"
                            onClick={hideSessionDetail}
                            className="text-sm"
                          >
                            Zavřít
                          </Button>
                        </CardActions>
                      </Card>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <Schedule
              hourStart={programDay.hourStart}
              hourEnd={programDay.hourEnd}
              resolution={programDay.resolution}
              tracks={programDay.tracks}
            >
              {programDay.tracks.map((track) => (
                <TrackSlot
                  key={track.code}
                  track={track.code}
                  className="leading-normal"
                >
                  <h3 className="head-alt-sm">{track.name}</h3>
                  <span className="text-base opacity-50">{track.venue}</span>
                </TrackSlot>
              ))}
              {programDay.slots.map((slot, idx) => (
                <React.Fragment key={idx}>
                  <TimeSlot
                    time={slot.time.replace(":", "")}
                    className="head-alt-xs"
                  >
                    {slot.time}
                  </TimeSlot>

                  {slot.sessions.map((session) => (
                    <Session
                      key={[
                        session.track,
                        session.timeStart,
                        session.timeEnd,
                      ].join("__")}
                      className={`track-${session.track} leading-tight md:elevation-8`}
                      track={session.track}
                      timeStart={session.timeStart.replace(":", "")}
                      timeEnd={session.timeEnd.replace(":", "")}
                    >
                      <div className="flex">
                        <strong className="text-xs opacity-75">
                          {session.timeEnd
                            ? `${session.timeStart} - ${session.timeEnd}`
                            : session.timeStart}
                        </strong>
                        <button
                          className="text-xs opacity-75 ml-auto font-bold underline"
                          onClick={(evt) => showSessionDetail(evt, session)}
                        >
                          Podrobnosti
                        </button>
                      </div>
                      <h3 className="text-sm font-bold">
                        {session.link ? (
                          <a
                            href={session.link}
                            className="underline"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {session.title}
                          </a>
                        ) : (
                          session.title
                        )}
                      </h3>
                    </Session>
                  ))}
                </React.Fragment>
              ))}
            </Schedule>
          </div>
        ))}
    </article>
  );
};

export default Program;
